/* global Isotope */
// homepage only
if (!document.querySelector('.not-homepage')) {
  $(document).ready(() => {
    $('.testimonials-slider').slick({
      centerMode: true,
      centerPadding: '0px',
      slidesToShow: 3,
      prevArrow:
        '<img class="slick-prev" src="http://localhost:8000/wp-content/themes/south-alliance/img/horizontal-arrow.png">',
      nextArrow:
        '<img class="slick-next" src="http://localhost:8000/wp-content/themes/south-alliance/img/horizontal-arrow.png">',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $('.partners-slider').slick({
      centerMode: true,
      centerPadding: '0px',
      slidesToShow: 5,
      prevArrow:
        '<img class="slick-prev" src="http://localhost:8000/wp-content/themes/south-alliance/img/horizontal-gray-arrow.png">',
      nextArrow:
        '<img class="slick-next" src="http://localhost:8000/wp-content/themes/south-alliance/img/horizontal-gray-arrow.png">',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });

  if (window.innerWidth >= 768) {
    const observedElements = [
      document.querySelector('.services-wrap'),
      document.querySelector('.testimonials-wrap'),
      document.querySelector('.reasons-wrap>.container'),
      document.querySelector('.partners-wrap>.container'),
    ];
    const options = {
      rootMargin: '-200px',
    };
    const homepageSectionsObserver = new IntersectionObserver((entry) => {
      if (entry[0].isIntersecting) {
        entry[0].target.classList.add('animated', 'slideInUp');
        homepageSectionsObserver.unobserve(entry[0].target);
      }
    }, options);

    observedElements.map((el) => homepageSectionsObserver.observe(el));

    const homepageOverlayObserver = new IntersectionObserver((entry) => {
      if (entry[0].isIntersecting) {
        entry[0].target.classList.add('animated', 'zoomIn');
        homepageSectionsObserver.unobserve(entry[0].target);
      }
    });
    homepageOverlayObserver.observe(document.querySelector('.slide-overlay'));
  }
}


// end of homepage only

const elem = document.querySelector('.seguros-container');
const layoutMode = window.innerWidth <= 768 ? 'horiz' : 'masonry';
if (elem) {
  const arrOfPossibleTypes = Array.from(
    document.querySelector('.filter-button-group').children,
  );
  let searchBarInputValue = '';
  let currentElementType = '';
  const iso = new Isotope(elem, {
    itemSelector: '.seguros-item',
    layoutMode,
    filter() {
      const elementMatchesTitleText = filterElementsByTitle(this);
      const elementIsInSelectedType = filterElementsByType(
        this,
        currentElementType,
      );

      return elementMatchesTitleText && elementIsInSelectedType;
    },
  });

  function filterElementsByTitle(currentElement) {
    const elementTitle = currentElement.querySelector('.seguros-title')
      .textContent;
    const regx = new RegExp(`^.*${searchBarInputValue}.*`, 'gmi');
    return elementTitle.match(regx);
  }

  const searchBar = document.querySelector('.input-search');
  searchBar.addEventListener('keyup', (e) => {
    searchBarInputValue = e.target.value;
    iso.arrange();
  });

  function filterElementsByType(currentElement, elementType) {
    if (elementType === '' || elementType === '*') {
      return true;
    }
    return currentElement.classList.contains(elementType);
  }

  function addListenerToCategories() {

    for (let type in arrOfPossibleTypes) {
      arrOfPossibleTypes[type].addEventListener('click', (e) => {
        arrOfPossibleTypes
          .find((el) => el.classList.contains('is-checked'))
          .classList.remove('is-checked');

        e.target.classList.add('is-checked');
        currentElementType = e.target.attributes['data-filter'].value;
        iso.arrange();
      });
    }
  }

  addListenerToCategories();
}

// start all pages

if (window.innerWidth >= 768) {
  const headerObserver = new IntersectionObserver((entry) => {
    if (entry[0].isIntersecting) {
      entry[0].target.classList.add('animated', 'slideInDown');
      headerObserver.unobserve(entry[0].target);
    }
  });
  headerObserver.observe(document.querySelector('.site-header'));

  const allPagesSlideInUpElements = [
    document.querySelector('.site-content'),
    document.querySelector('#colophon>.container'),
  ];
  const allPagesSlideInUpObserver = new IntersectionObserver((entry) => {
    if (entry[0].isIntersecting) {
      entry[0].target.classList.add('animated', 'slideInUp');
      allPagesSlideInUpObserver.unobserve(entry[0].target);
    }
  });
  allPagesSlideInUpElements.map((el) => allPagesSlideInUpObserver.observe(el));
}

// MOBILE HOMEPAGE

// fazer < 768 e testar homepage

function addButtonGroupWidth() {
  const buttonsContainer = document.querySelector('section.services-wrap .button-group-container .button-group');
  const totalWidth = [...buttonsContainer.children].reduce((acumulator, currentItem) => acumulator + (currentItem.offsetWidth + 5), 0);

  buttonsContainer.style.width = `${totalWidth + 30}px`;
}

addButtonGroupWidth();


const reasons = Array.from(document.querySelectorAll('.reason-item'))
let startX, endX
reasons.map(reason => {
  reason.addEventListener('touchstart', (evt) => {
    evt.stopPropagation()
    startX = evt.changedTouches[0].clientX
  })
  reason.addEventListener('touchend', (evt) => {
    evt.stopPropagation()
    endX = evt.changedTouches[0].clientX
    if(startX !== endX) {
      return
    }
    evt.target.classList.toggle('event-enabled')
  })
})

